import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { Box } from "@mui/system";
import AdExtensionDialog from "./AdExtensionDialog";

export default function AdExpirationController({ ad }) {
  const expirationUtcTime = new Date(ad.properties.expires_at);
  const expirationLocalTime = new Date(
    expirationUtcTime.getTime() - expirationUtcTime.getTimezoneOffset() * 60000
  );
  const expirationDateString = expirationLocalTime.toISOString().split("T")[0];
  const expired =
    new Date(ad.properties.expires_at) < new Date() &&
    ad.properties.status === "expired";
  const now = new Date();
  const weekFromNow = new Date();
  weekFromNow.setTime(now.getTime() + 7 * 24 * 60 * 60 * 1000);
  const expiresInLessThanAWeek =
    new Date(ad.properties.expires_at) < weekFromNow;
  const [selectedAdId, setSelectedAdId] = useState(null);

  const [openAdExtensionDialog, setOpenAdExtensionDialog] = useState(false);

  function handleExtendAdClick() {
    setSelectedAdId(ad.id);
    setOpenAdExtensionDialog(true);
  }

  // console.log("ad from AdExpirationController", ad.id);

  return (
    <Box
      sx={{
        display: "flex",
        border: "none",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", border: "none" }}>
        <QueryBuilderIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
        {!expired ? (
          <Typography
            variant="caption"
            color={expiresInLessThanAWeek ? "warning.main" : "inherit"}
          >
            Galioja iki {expirationDateString}
          </Typography>
        ) : (
          <Typography variant="caption" color="error.main">
            Baigė galioti {expirationDateString}
          </Typography>
        )}
      </Box>
      <Button
        variant="outlined"
        size="small"
        startIcon={<UpdateIcon color="action" />}
        sx={{ mx: 0.5, width: "120px", border: "solid lightgrey 1px" }}
        color="inherit"
        onClick={handleExtendAdClick}
      >
        <Typography variant="caption">Pratęsti</Typography>
      </Button>
      {selectedAdId === ad.id && openAdExtensionDialog && (
        <AdExtensionDialog
          open={openAdExtensionDialog}
          setOpen={setOpenAdExtensionDialog}
          ad={ad}
        />
      )}
    </Box>
  );
}
