import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  List,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdMap from "./AdMap";
import { useGlobalContext } from "../../../context";
import { FEATURE_OPTIONS } from "../../../utils/defaults";
import StyledListItem from "../AccordionDetail/StyledListItem";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const AccordionMap = ({ ad }) => {
  const [expanded, setExpanded] = useState(false);
  const { state } = useGlobalContext();
  const amenities = state?.ad?.infrastructure;
  const sortedAmenities = amenities?.sort(
    (a, b) => a.properties.distance - b.properties.distance
  );
  const displayedAmenities = sortedAmenities?.slice(0, 5);

  // console.log("amenities", amenities);
  return (
    <Accordion
      expanded={expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      sx={{ width: "100%" }}
      // CollapseProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="button" color={expanded ? "primary" : "default"}>
          Vieta žemėlapyje
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        <Box
          sx={{
            border: "none",
            width: "100%",
            height: "450px",
          }}
        >
          {expanded && <AdMap ad={ad} showMap={expanded} />}
        </Box>
        {!!displayedAmenities?.length && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Artimiausi objektai kategorijoje "
              {
                FEATURE_OPTIONS.find(
                  (category) =>
                    category.value === displayedAmenities[0].properties.category
                ).label
              }
              "
            </Typography>
            <List dense>
              {displayedAmenities.map((amenity, index) => (
                <StyledListItem key={index}>
                  <Typography variant="body2" component="span">
                    {amenity.properties.name} ({amenity.properties.distance} m)
                  </Typography>
                </StyledListItem>
              ))}
            </List>
          </>
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ width: "100%", alignItems: "center", my: 2 }}
        >
          <WarningAmberIcon color="warning" fontSize="small" />
          <Typography variant="caption" sx={{ mb: 2 }}>
            Informacija apie aplinkinius objektus paimta iš viešai prieinamų
            duomenų bazių. Ši informacija gali būti nepilna ir/ar netiksli,
            tačiau ji yra reguliariai atnaujinama ir pildoma.
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMap;
