import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { fetchAdViewHistory } from "../../fetchOrSendDashboardData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, getElementsAtEvent } from "react-chartjs-2";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useBackHandler from "../../../../hooks/useBackHandler";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../utils/Spinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AdViewHistory({ open, setOpen, ad }) {
  const { dashboardState, dispatchDashboard } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const chartRef = useRef(null);
  const [cumulative, setCumulative] = useState(true);
  const history = useHistory();
  useBackHandler(handleClose);

  let viewsData = dashboardState?.adViewsData?.map(
    (data) => data.cumulative_count
  );
  // console.log("viewsData", viewsData);
  const dailyViewsData = viewsData?.map((value, index) =>
    index === 0 ? value : value - viewsData[index - 1]
  );
  viewsData = cumulative ? viewsData : dailyViewsData;

  let likesData = dashboardState?.adLikesData?.map(
    (data) => data.cumulative_count
  );
  const dailyLikesData = likesData?.map((value, index) =>
    index === 0 ? value : value - likesData[index - 1]
  );
  likesData = cumulative ? likesData : dailyLikesData;

  const chartData = {
    labels: dashboardState?.adViewsData?.map((data) => data.date),
    datasets: [
      {
        label: "Peržiūrėjo",
        data: viewsData,
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        pointRadius: 1,
        stepped: false,
        yAxisID: "views",
      },
      {
        label: "Įsiminė",
        data: likesData,
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        pointRadius: 1,
        stepped: false,
        yAxisID: "views",
      },
    ],
  };

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "bottom",
        onClick: handleLegendClick,
      },
      title: {
        display: false,
        text: "Peržiūrų skaičius",
      },
    },
    scales: {
      views: {
        type: "linear",
        display: true,
        position: "left",
        beginAtZero: true,
        ticks: { stepSize: 1 },
        // max: !!viewsData?.length ? Math.max(...viewsData) + 1 : 1,
      },
    },
  });

  useEffect(() => {
    if (dashboardState?.adViewHistoryLoaded !== ad.id) {
      fetchAdViewHistory(dispatchDashboard, ad.id);
    } else {
      let newChartOptions = chartOptions;
      newChartOptions = {
        ...newChartOptions,
        scales: {
          ...newChartOptions.scales,
          views: {
            ...newChartOptions.scales.views,
            max: !!viewsData?.length ? Math.max(...viewsData) + 1 : 1,
          },
        },
      };
      newChartOptions.plugins.legend.onClick = handleLegendClick;
      setChartOptions(newChartOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ad.id,
    dashboardState?.adLikesData,
    dashboardState?.adViewHistoryLoaded,
    dashboardState?.adViewsData,
    dispatchDashboard,
    cumulative,
  ]);

  function handleLegendClick(event, legendItem, legend) {
    // hide series when clicked on legendItem
    const datasetIndex = legendItem.datasetIndex;
    const metaSets = [0, 1].map((index) =>
      chartRef.current.getDatasetMeta(index)
    );
    // hide scale if series hidden
    let newChartOptions = { ...chartOptions };
    metaSets.forEach((meta) => {
      meta.index === datasetIndex
        ? (meta.hidden = false)
        : (meta.hidden = !meta.hidden);
    });
    const scaleMax = Math.max(
      metaSets[0].hidden ? 1 : Math.max(...viewsData) + 1,
      metaSets[1].hidden ? 1 : Math.max(...likesData) + 1
    );
    newChartOptions = {
      ...newChartOptions,
      scales: {
        ...newChartOptions.scales,
        views: {
          ...newChartOptions.scales.views,
          max: scaleMax,
        },
      },
    };
    setChartOptions(newChartOptions);
  }

  function handleClose() {
    setOpen(false);
  }

  // console.log("viewsData", viewsData, "likesData", likesData);

  return (
    <Dialog
      open={open}
      onClose={() => {
        history.goBack();
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      //   PaperProps={{ sx: { minWidth: 200 } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Skelbimo peržiūros ir įsiminimai
      </DialogTitle>
      <DialogContent>
        {!!dashboardState.adViewHistoryLoading ? (
          <Spinner />
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ mr: 2 }}>
                Išraiška:
              </Typography>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        checked={cumulative}
                        onChange={() => setCumulative(!cumulative)}
                        // value={true}
                        size="small"
                      />
                    }
                    label={<Typography variant="body2">Iš viso</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        checked={!cumulative}
                        onChange={() => setCumulative(!cumulative)}
                        // value={false}
                        size="small"
                      />
                    }
                    label={<Typography variant="body2">Per dieną</Typography>}
                    labelPlacement="end"
                    sx={{ mr: 3 }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Line
              data={chartData}
              options={chartOptions}
              // onClick={handleChartClick}
              ref={chartRef}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            history.goBack();
            handleClose();
          }}
          variant="contained"
        >
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdViewHistory;
