import React from "react";
import { InputAdornment } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { Form, Formik } from "formik";
import FormikTextField from "../../SharedComponents/FormikTextField";
import * as Yup from "yup";
// import FormPreview from "../../AdForm/utils/FormPreview";
import { getMonthlyPaymentOrDuration } from "./Price";
import { PV } from "../../../utils/financialFunctions";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

function Downpayment() {
  const { loanState, dispatchLoan } = useGlobalContext();
  const price = loanState.price;
  const { isMobile } = useCustomMediaQuery();

  let minDownpayment = (price * loanState.minDownPercent) / 100;
  if (loanState.fixMonthlyPayment) {
    const maxLoanSize = PV(
      loanState.interestRate / 12 / 100,
      loanState.maxDuration * 12,
      -loanState.monthlyPayment,
      0,
      0
    );
    minDownpayment = Math.round(Math.max(minDownpayment, price - maxLoanSize));
  }
  // const downpayment = Math.round(loanState.downpayment || minDownpayment);
  const downpayment = Math.round(
    (loanState.price * loanState.downPercent) / 100
  );

  const initialValues = { downpayment };
  const validationSchema = Yup.object({
    downpayment: Yup.number()
      .positive("Turi būti teigiamas skaičius")
      .required("Turi būti skaičius")
      .max(price, "Pradinis įnašas negali viršyti būsto vertės")
      .min(minDownpayment, "Nepakankamas pradinis įnašas"),
  });

  function handleSubmit(formikValues) {
    let downpayment = +formikValues.downpayment;
    let loanSize = price - downpayment;
    let monthlyPayment, duration;
    ({ monthlyPayment, duration, loanSize } = getMonthlyPaymentOrDuration({
      loanState,
      loanSize,
    }));
    downpayment = Math.round(price - loanSize);
    let downPercent = (1 - loanSize / price) * 100;
    dispatchLoan({
      type: "CHANGE_LOAN_STATE",
      payload: {
        downpayment,
        downPercent,
        loanSize,
        monthlyPayment,
        duration,
      },
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnChange={true}
    >
      {(formik) => (
        <Form noValidate>
          <FormikTextField
            sx={{
              width: isMobile ? "100%" : "13ch",
            }}
            autoComplete="off"
            size="small"
            label="Pradinis įnašas"
            name="downpayment"
            onChange={async (newValue) => {
              formik.setFieldValue("downpayment", newValue, true);
              await formik.validateField("downpayment");
              formik.submitForm();
            }}
            type="number"
            InputProps={{
              sx: { fontSize: "14px" },
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: {
                min: 0,
                max: price,
                step: 500,
              },
            }}
          />
          {/* <FormPreview /> */}
        </Form>
      )}
    </Formik>
  );
}

export default Downpayment;
