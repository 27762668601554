import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useAdType from "./useAdType";
import {
  getAddress,
  getArea,
  getBuildingType,
  getConstructionYear,
  getEnergyEfficiency,
  getFloor,
  getHeatingBill,
  getInstallation,
  getPlotArea,
  getPrice,
  getPriceSqm,
  getRooms,
} from "./utils";
import { Finance } from "financejs";
import { initialLoanState } from "../../../reducers/loanReducer";
import MapButton from "./MapButton";

function BasicAdInfoFlatHouse({ ad, setShowMap, showMap }) {
  const finance = new Finance();
  const monthlyPayment = Math.round(
    finance.PMT(
      initialLoanState.interestRate / 100 / 12,
      initialLoanState.duration * 12,
      -ad.properties.price * (1 - initialLoanState.downPercent / 100)
    )
  );

  const { isFlat, isHouse, isSale, isRent, isLand } = useAdType(ad);

  const price = getPrice(ad, isRent);
  const priceSqm = getPriceSqm(ad);
  const address = getAddress(ad);
  const rooms = getRooms(ad, 6, 4);
  const area = getArea(ad, 6, 4);
  const floor = getFloor(ad, 6, 4, isFlat, isHouse);
  const buildingType = getBuildingType(ad, 6, 4);
  const plotArea = getPlotArea(ad, 6, 4, isLand);
  const constructionYear = getConstructionYear(ad, 6, 4);
  const installation = getInstallation(ad, 6, 4);
  const heatingBill = getHeatingBill(ad, 6, 4);
  const energyEfficiency = getEnergyEfficiency(ad, 6, 4);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sx={{
          border: "none",
          justifyContent: "space-between",
          // display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ mt: 1, mx: 1 }}
            color="primary"
            variant="body2"
            fontSize={20}
          >
            {price}
          </Typography>
          <Typography sx={{ mt: 1, pt: 0.3 }} variant="body2">
            ({priceSqm} €/m²)
          </Typography>
          {isRent && (
            <Typography sx={{ mt: 1, pt: 0.3, mx: 1 }} variant="body2">
              {ad.properties.utils_included
                ? "komunaliniai įskaičiuoti"
                : "+ komunaliniai"}
            </Typography>
          )}
        </Box>
        {isSale && (
          <Typography sx={{ mt: 1, mx: 1 }} variant="caption">
            Nuo {monthlyPayment} €/mėn.
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          border: "none",
          alignItems: "center",
          px: 1,
          mt: 0.2,
        }}
      >
        <Typography variant="caption">{address} </Typography>
        <MapButton setShowMap={setShowMap} showMap={showMap} />
      </Grid>

      <Grid container sx={{ my: 1, pl: 1, border: "none" }}>
        {rooms}
        {area}
        {isFlat && floor}
        {isHouse && plotArea}
        {buildingType}
        {constructionYear}
        {isSale && installation}
        {isRent && (heatingBill || energyEfficiency)}
      </Grid>
    </>
  );
}

export default BasicAdInfoFlatHouse;
