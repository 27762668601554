import React, { useEffect } from "react";
import {
  checkVisitor,
  fetchUserData,
  fetchUserId,
  getContactAvatarData,
} from "../../Accounts/fetchOrSendAccountsData";
import { useGlobalContext } from "../../../context";
import { crudSavedSearch } from "../../Dashboard/fetchOrSendDashboardData";
import { useHistory, useLocation } from "react-router-dom";
import initFacebookPixel from "../../../analytics/facebookPixel";

function HeaderDataProvider({ children }) {
  const { userState, dispatchUser, dispatchDashboard, routerState } =
    useGlobalContext();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    initFacebookPixel();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("userState", JSON.stringify(userState));
  }, [userState]);

  // On load, check if the refresh token is present and get a new access token
  useEffect(() => {
    // dispatchUser({
    //   type: "CHANGE_USER_STATE",
    //   payload: { userLoggingIn: true },
    // });
    fetchUserId(dispatchUser);
  }, []);

  useEffect(() => {
    if (userState.isAuthenticated && userState.userId) {
      fetchUserData(userState.userId, dispatchUser);
      getContactAvatarData(dispatchUser);
      crudSavedSearch(dispatchDashboard, "get");
      if (userState.userCreated) {
        history.push("/user-profile");
        const snackbar = userState.snackbar;
        snackbar.open = true;
        snackbar.message = "Sveiki prisijungę! Paskyra sėkmingai sukurta.";
        snackbar.severity = "success";
        dispatchUser({
          type: "CHANGE_USER_STATE",
          payload: { snackbar },
        });
      } else if (["login", "register"].includes(routerState.from)) {
        // alert("redirecting " + location.state?.from);
        history.replace(location.state?.from || "/");
      }
    }
  }, [userState.userId]);

  useEffect(() => {
    if (!userState?.visitorLoaded) {
      checkVisitor(dispatchUser);
    }
  }, [dispatchUser, userState?.visitorLoaded]);

  return <>{children}</>;
}

export default HeaderDataProvider;
