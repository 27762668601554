import { Box, Container, Paper, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import useParamsFromUrl from "../MainPage/hooks/useParamsFromUrl";
import { validatePayment } from "./fetchOrSendAdDraftData";
import { useGlobalContext } from "../../context";
import AdCard from "../MainPage/MainListing/AdCard";
import { NavLink, useHistory } from "react-router-dom";
import Spinner from "../../utils/Spinner";
import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import { anonymousAdFormState } from "../../reducers/adFormReducer";
import ReactPixel from "react-facebook-pixel";
import { fetchMyOrders } from "../Dashboard/fetchOrSendDashboardData";

export default function AdActivationPage() {
  const params = useParamsFromUrl();
  const token = params["order-token"];
  const {
    adFormState,
    dispatch,
    dispatchAdForm,
    dashboardState,
    dispatchDashboard,
    dispatchRouter,
  } = useGlobalContext();
  const [ad, setAd] = useState(adFormState.ad || null);
  const history = useHistory();
  const { isMobile } = useCustomMediaQuery();

  //   async function asyncValidatePayment() {
  //     const res = await validatePayment(
  //       token,
  //       dispatch,
  //       dispatchAdForm,
  //       dispatchDashboard
  //     );
  //     setAd(res.adCard);
  //   }

  // track free activation
  useEffect(() => {
    if (adFormState.freeAd) {
      ReactPixel.track("Purchase", { value: 0, currency: "EUR" });
    }
  }, []);

  // check my orders
  useEffect(() => {
    if (!dashboardState.myOrdersLoaded && !dashboardState.myOrdersLoading) {
      fetchMyOrders(dispatchDashboard);
    }
  }, []);

  // track purchase value
  useEffect(() => {
    if (
      !adFormState.freeAd &&
      adFormState.paymentValidated &&
      dashboardState.myOrdersLoaded &&
      ad
    ) {
      let value =
        dashboardState.myOrders.find((order) => order.ad === ad.id)?.payment
          ?.amount || 0;
      ReactPixel.track("Purchase", { value, currency: "EUR" });
    }
  }, [
    ad,
    adFormState.freeAd,
    adFormState.paymentValidated,
    dashboardState.myOrders,
    dashboardState.myOrdersLoaded,
  ]);

  useEffect(() => {
    if (
      token &&
      !adFormState.paymentValidated &&
      !adFormState.paymentValidating
    ) {
      validatePayment(token, dispatch, dispatchAdForm, dispatchDashboard);
    }
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "createAd" },
    });
    sessionStorage.removeItem("adFormState");
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { ...anonymousAdFormState },
    });
  }, []);

  useEffect(() => {
    setAd(adFormState.ad);
  }, [adFormState.ad]);

  useEffect(() => {
    if (!!adFormState.paymentValidated) {
      const location = history.location;
      history.replace(location.pathname);
    }
  }, [adFormState.paymentValidated]);

  // console.log(
  //   "ad from AdActivationPage",
  //   ad,
  //   adFormState.paymentValidated,
  //   adFormState.paymentValidating
  // );

  return (
    <Box sx={{ bgcolor: grey[200], height: "100vh", display: "flex" }}>
      {!!adFormState.paymentValidating && <Spinner />}
      {!adFormState.paymentValidating &&
        !!adFormState.paymentValidated &&
        !!ad && (
          <Container
            maxWidth="md"
            sx={{ border: "none", mt: 7, pt: 2, px: 0.5 }}
            disableGutters
          >
            <Paper
              sx={{
                backgroundColor: blue[100],
                mb: 2,
                display: "block",
                p: 1,
              }}
            >
              <Box
                justifyContent="center"
                sx={{ display: "flex", width: "100%" }}
              >
                <Typography variant="h6" color="success.main" sx={{ mb: 2 }}>
                  {adFormState?.freeAd
                    ? "Skelbimo aktyvavimas sėkmingas"
                    : "Ačiū, mokėjimas gautas!"}
                </Typography>
              </Box>
              <Typography variant={isMobile ? "body2" : "body1"}>
                Skelbimas aktyvuotas ir galios iki{" "}
                <b>
                  {new Date(ad.properties.expires_at).toLocaleDateString(
                    "en-CA"
                  )}
                </b>
                .
              </Typography>
              <Box
                sx={{
                  display: isMobile ? "block" : "flex",
                  align: "center",
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ mr: 1, display: "flex" }}>
                  Skelbimą galite stebėti ir valdyti skiltyje
                </Typography>
                <Typography variant="body2">
                  <NavLink
                    to="/dashboard/my-ads"
                    style={{ color: "inherit", whiteSpace: "nowrap" }}
                  >
                    Mano erdvė {"\u21E8"} Mano skelbimai
                  </NavLink>
                </Typography>
              </Box>
            </Paper>
            <Box>
              <AdCard ad={ad} />
            </Box>
          </Container>
          // <Typography sx={{ mt: 20 }}>{JSON.stringify(adCard)}</Typography>
        )}
    </Box>
  );
}
